import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = [
    "kind",
    "tagNameWrapper",
    "folderWrapper"
  ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    hideTarget(this.tagNameWrapperTarget)
    hideTarget(this.folderWrapperTarget)
    const kind = this.kindTarget.value
    switch(kind) {
      case "apply_tag":
        showTarget(this.tagNameWrapperTarget)
        hideTarget(this.folderWrapperTarget)
        break
      case "move_to_folder":
        hideTarget(this.tagNameWrapperTarget)
        showTarget(this.folderWrapperTarget)
        break
      default:
        throw new Error(`Unknown kind: ${kind}`)
    }
  }
}

