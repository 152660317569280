import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = [
    "input",
    "formWrapper",
    "checkbox",
    "selectAllCheckbox"
  ]

  connect() {
    this.recalculate()
  }

  recalculate() {
    const selectedValues = this.checkboxTargets
      .filter(target => target.checked)
      .map(target => target.value)
      .join(",")

    this.inputTargets.forEach(target => (target.value = selectedValues))

    if(selectedValues) {
      showTarget(this.formWrapperTargets)
    }
    else {
      hideTarget(this.formWrapperTargets)
    }
  }

  toggleSelectAll() {
    const anyUnchecked = this.checkboxTargets.filter(target => !target.checked).length > 1
    if(anyUnchecked) {
      this.checkboxTargets.forEach(target => (target.checked = true))
    }
    else {
      this.checkboxTargets.forEach(target => (target.checked = false))
    }
    this.recalculate()
  }
}

