import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = [
    "action",
    "folderWrapper",
    "jobNameWrapper",
  ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const action = this.actionTarget.value
    switch(action) {
      case "Run Job":
        showTarget(this.jobNameWrapperTarget)
        hideTarget(this.folderWrapperTarget)
        break
      case "Move to Folder":
        hideTarget(this.jobNameWrapperTarget)
        showTarget(this.folderWrapperTarget)
        break
      default:
        throw new Error(`Unknown action: ${action}`)
    }
  }
}

