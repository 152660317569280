import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

const refreshInterval = 30 * 1000

export default class extends Controller {
  static targets = [ "map" ]
  static values = { url: String }

  connect() {
    this.map = window.L.map('vehicle-map').setView([33.8591599, -117.8657205], 13);
    window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 9,
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.markers = {}

    this.timer = setInterval(() => { this.refresh() }, refreshInterval)
    this.refresh()
  }

  disconnect() {
    clearInterval(this.timer)
  }

  async refresh() {
    console.log(`Refreshing vehicles`)

    const response = await fetch(this.urlValue)
    const parsed = await response.json()
    parsed.forEach(vehicle => {
      // const markerHtml = `
      //   <div class="vehicle-marker">
      //     ${vehicle.driver_name || `Vehicle #${vehicle.vehicle_number}`}
      //   </div>
      // `
      const vehicleStatusClass = htmlClassForVehicleStatus(vehicle)
      const vehicleStatusIcon = htmlIconForVehicleStatus(vehicle)
      const rotationForHeading = rotationForVehicle(vehicle)

      const division = vehicle.division
      const markerHtml = `
        <div class="vehicle-icon ${vehicleStatusClass}">
          <div class="icon-wrapper ${vehicleStatusClass}">
            <i class="${vehicleStatusIcon}" style="${vehicleStatusClass == "moving" ? `transform: rotate(${rotationForHeading}deg)` : ""}"></i>
          </div>
          <div class="driver">
            ${vehicle.driver_name || `Vehicle #${vehicle.vehicle_number}`}
            ${ division ? `<span class="division">(${division})</div>` : "" }
          </div>
        </div>
        `

      const popupHtml = `
        <div class="vehicle-popup">
          <div><strong>Driver Name:</strong> ${vehicle.driver_name}</div>
          <div><strong>Vehicle Number:</strong>: ${vehicle.vehicle_number}</div>
          <div><strong>Vehicle Status:</strong> ${vehicle.display_state || "Unknown"}</div>
          <div><strong>Vehicle Type & Color:</strong> ${vehicle.vehicle_type_color}</div>
          <div><strong>License:</strong> ${vehicle.license}</div>
          <div><strong>Address:</strong> ${vehicle.address}</div>
          <div class="vehicle-popup-links"><a href="/vehicles/${vehicle.id}">Vehicle Details</a></div>
        </div>
      `
      if(this.markers[vehicle.id]) {
        this.markers[vehicle.id].setLatLng([vehicle.latitude, vehicle.longitude])
      }
      else {
        this.markers[vehicle.id] = window.L.marker([vehicle.latitude, vehicle.longitude], { icon: window.L.divIcon({ html: markerHtml }) }).addTo(this.map)
        // this.markers[vehicle.id] = window.L.marker([vehicle.latitude, vehicle.longitude]).addTo(this.map)
      }
      this.markers[vehicle.id].bindPopup(popupHtml)
    })
  }
}

function htmlClassForVehicleStatus(vehicle) {
  if(vehicle.display_state == "Idle") {
    return "idle"
  }
  else if(vehicle.display_state == "Moving") {
    return "moving"
  }
  else if(vehicle.display_state == "Stop") {
    return "stop"
  }
  else if(vehicle.display_state == "Towing") {
    return "towing"
  }
  else {
    return "stop"
  }

}

function htmlIconForVehicleStatus(vehicle) {
  if(vehicle.display_state == "Idle") {
    return "fa fa-pause idle icon"
  }
  else if(vehicle.display_state == "Moving") {
    return "fa fa-play moving icon"
  }
  else if(vehicle.display_state == "Stop") {
    return "fa fa-stop stop icon"
  }
  else if(vehicle.display_state == "Towing") {
    return "fa fa-truck towing icon"
  }
  else {
    return "fa fa-stop stop vehicle-icon"
  }
}

function rotationForVehicle(vehicle) {
  if(vehicle.heading == "South East") {
    return 45
  }
  else if(vehicle.heading == "South") {
    return 90
  }
  else if(vehicle.heading == "South West") {
    return 135
  }
  else if(vehicle.heading == "West") {
    return 180
  }
  else if(vehicle.heading == "North West") {
    return 225
  }
  else if(vehicle.heading == "North") {
    return 270
  }
  else if(vehicle.heading == "North East") {
    return 315
  }
  else if(vehicle.heading == "East") {
    return 0
  }
  else {
    return 0
  }
}
