import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = [
    "copyCallerToOwner", "copyLossSiteToOwner", "copyOwnerToJobSite", "copyOwnerToBilling",
    "callerFirstName", "callerLastName", "callerHomePhone", "callerWorkPhone", "callerMobilePhone", "callerEmail",
    "propertyOwnerFirstName", "propertyOwnerLastName", "propertyOwnerHomePhone", "propertyOwnerWorkPhone", "propertyOwnerMobilePhone", "propertyOwnerEmail", "propertyOwnerStreetAddress1", "propertyOwnerStreetAddress2", "propertyOwnerCity", "propertyOwnerState", "propertyOwnerZip",
    "lossSiteStreetAddress1", "lossSiteStreetAddress2", "lossSiteCity", "lossSiteState", "lossSiteZip",
    "jobSiteHomePhone", "jobSiteWorkPhone", "jobSiteMobilePhone", "jobSiteEmail",
    "billingAddressStreetAddress1", "billingAddressStreetAddress2", "billingAddressCity", "billingAddressState", "billingAddressZip",

  ]

  connect() {
    this.refresh()
  }

  refresh() {
    if(this.copyCallerToOwnerTarget.checked) {
      this.copyCallerToOwner()
      this.disableOwner()
    }
    else {
      this.enableOwner()
    }

    if(this.copyLossSiteToOwnerTarget.checked) {
      this.copyLossSiteToOwner()
      this.disableOwnerAddress()
    }
    else {
      this.enableOwnerAddress()
    }

    if(this.copyOwnerToJobSiteTarget.checked) {
      this.copyOwnerToJobSite()
      this.disableJobSite()
    }
    else {
      this.enableJobSite()
    }

    if(this.copyOwnerToBillingTarget.checked) {
      this.copyOwnerToBilling()
      this.disableBillingAddress()
    }
    else {
      this.enableBillingAddress()
    }
  }

  copyCallerToOwner() {
    this.propertyOwnerFirstNameTarget.value = this.callerFirstNameTarget.value
    this.propertyOwnerLastNameTarget.value = this.callerLastNameTarget.value
    this.propertyOwnerEmailTarget.value = this.callerEmailTarget.value
    this.propertyOwnerHomePhoneTarget.value = this.callerHomePhoneTarget.value
    this.propertyOwnerWorkPhoneTarget.value = this.callerWorkPhoneTarget.value
    this.propertyOwnerMobilePhoneTarget.value = this.callerMobilePhoneTarget.value
  }

  copyLossSiteToOwner() {
    this.propertyOwnerStreetAddress1Target.value = this.lossSiteStreetAddress1Target.value
    this.propertyOwnerStreetAddress2Target.value = this.lossSiteStreetAddress2Target.value
    this.propertyOwnerCityTarget.value = this.lossSiteCityTarget.value
    this.propertyOwnerStateTarget.value = this.lossSiteStateTarget.value
    this.propertyOwnerZipTarget.value = this.lossSiteZipTarget.value
  }

  copyOwnerToJobSite() {
    this.jobSiteHomePhoneTarget.value = this.propertyOwnerHomePhoneTarget.value
    this.jobSiteWorkPhoneTarget.value = this.propertyOwnerWorkPhoneTarget.value
    this.jobSiteMobilePhoneTarget.value = this.propertyOwnerMobilePhoneTarget.value
    this.jobSiteEmailTarget.value = this.propertyOwnerEmailTarget.value
  }

  copyOwnerToBilling() {
    this.billingAddressStreetAddress1Target.value = this.propertyOwnerStreetAddress1Target.value
    this.billingAddressStreetAddress2Target.value = this.propertyOwnerStreetAddress2Target.value
    this.billingAddressCityTarget.value = this.propertyOwnerCityTarget.value
    this.billingAddressStateTarget.value = this.propertyOwnerStateTarget.value
    this.billingAddressZipTarget.value = this.propertyOwnerZipTarget.value
  }

  disableOwner() {
    disableTarget(this.propertyOwnerFirstNameTarget)
    disableTarget(this.propertyOwnerLastNameTarget)
    disableTarget(this.propertyOwnerEmailTarget)
    disableTarget(this.propertyOwnerHomePhoneTarget)
    disableTarget(this.propertyOwnerWorkPhoneTarget)
    disableTarget(this.propertyOwnerMobilePhoneTarget)
  }

  disableOwnerAddress() {
    disableTarget(this.propertyOwnerStreetAddress1Target)
    disableTarget(this.propertyOwnerStreetAddress2Target)
    disableTarget(this.propertyOwnerCityTarget)
    disableTarget(this.propertyOwnerStateTarget)
    disableTarget(this.propertyOwnerZipTarget)
  }

  disableJobSiteAddress() {
    disableTarget(this.jobSiteStreetAddress1Target)
    disableTarget(this.jobSiteStreetAddress2Target)
    disableTarget(this.jobSiteCityTarget)
    disableTarget(this.jobSiteStateTarget)
    disableTarget(this.jobSiteZipTarget)
  }

  disableBillingAddress() {
    disableTarget(this.billingAddressStreetAddress1Target)
    disableTarget(this.billingAddressStreetAddress2Target)
    disableTarget(this.billingAddressCityTarget)
    disableTarget(this.billingAddressStateTarget)
    disableTarget(this.billingAddressZipTarget)
  }

  disableJobSite() {
    disableTarget(this.jobSiteHomePhoneTarget)
    disableTarget(this.jobSiteWorkPhoneTarget)
    disableTarget(this.jobSiteMobilePhoneTarget)
    disableTarget(this.jobSiteEmailTarget)
  }

  enableOwner() {
    enableTarget(this.propertyOwnerFirstNameTarget)
    enableTarget(this.propertyOwnerLastNameTarget)
    enableTarget(this.propertyOwnerEmailTarget)
    enableTarget(this.propertyOwnerHomePhoneTarget)
    enableTarget(this.propertyOwnerWorkPhoneTarget)
    enableTarget(this.propertyOwnerMobilePhoneTarget)
  }


  enableOwnerAddress() {
    enableTarget(this.propertyOwnerStreetAddress1Target)
    enableTarget(this.propertyOwnerStreetAddress2Target)
    enableTarget(this.propertyOwnerCityTarget)
    enableTarget(this.propertyOwnerStateTarget)
    enableTarget(this.propertyOwnerZipTarget)
  }


  enableJobSite() {
    enableTarget(this.jobSiteHomePhoneTarget)
    enableTarget(this.jobSiteWorkPhoneTarget)
    enableTarget(this.jobSiteMobilePhoneTarget)
    enableTarget(this.jobSiteEmailTarget)
  }

  enableBillingAddress() {
    enableTarget(this.billingAddressStreetAddress1Target)
    enableTarget(this.billingAddressStreetAddress2Target)
    enableTarget(this.billingAddressCityTarget)
    enableTarget(this.billingAddressStateTarget)
    enableTarget(this.billingAddressZipTarget)
  }

}

function disableTarget(target) {
  target.disabled = true
}

function enableTarget(target) {
  target.disabled = false
}
